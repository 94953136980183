<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <SectionContent style="background-color: #fff">
                <com-list-search>
                    <el-form :inline="true" size="medium" slot="list-search-before">
                        <el-form-item>
                            <ApiSelect placeholder="请选择消息类型" :options="messageTypeOptions" clearable
                                       v-model="form.messageType"/>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="请输入标题" style="width: 240px" clearable
                                      v-model="form.messageTitle"/>
                        </el-form-item>
                    </el-form>
                    <div slot="list-search-after">
                        <el-button @click="resetClick()">重置</el-button>
                        <el-button type="primary" @click="getList()">搜索</el-button>
                    </div>
                </com-list-search>
                <el-table style="width: 100%;" border v-loading="loading" :data="result.data">
                    <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                    <el-table-column prop="messageTitle" label="消息标题" width="150" align="center"></el-table-column>
                    <!--                        <el-table-column prop="messageInfo" label="消息内容" align="center" width="250">-->
                    <!--                            <template slot-scope="scope">-->
                    <!--                                <el-popover placement="top-start" :title="scope.row.messageTitle"-->
                    <!--                                            width="250"-->
                    <!--                                            trigger="hover"-->
                    <!--                                            :content="scope.row.messageInfo">-->
                    <!--                                    <span slot="reference" type="text" class="message-info-btn">-->
                    <!--                                        {{ scope.row.messageInfo }}-->
                    <!--                                    </span>-->
                    <!--                                </el-popover>-->
                    <!--                            </template>-->
                    <!--                        </el-table-column>-->
                    <el-table-column prop="messageType" label="消息类型" width="120" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.messageType | formatDesc(messageTypeOptions) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="pushTime" label="发布时间" width="150" align="center">
                        <template slot-scope="scope">
                            <p v-if="scope.row.pushTime">
                                消息将在 <span class="color-primary">{{
                                    scope.row.pushTime |dateFormat('MM-DD HH:mm')
                                }}</span>定时发布
                            </p>
                            <p v-else>否</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="channel" label="发布渠道" width="150" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.channel | formatDesc(messageChannelOptions) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="pushType" label="发布形式" width="150" align="center">
                        <template slot-scope="scope">
                            <p>消息列表</p>
                            <p v-if="scope.row.pushType">弹窗提醒</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isRepeat" label="是否循环" width="150" align="center">
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.pushType && scope.row.isRepeat ? `间隔${scope.row.timeSpan}小时循环` : '否' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建人" align="center" prop="createUserName"></el-table-column>
                    <!--                        <el-table-column label="创建时间" align="center">-->
                    <!--                            <template slot-scope="scope">-->
                    <!--                                {{ scope.row.createTime | dateFormat }}-->
                    <!--                            </template>-->
                    <!--                        </el-table-column>-->
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.status | filterStateType(scope.row)">
                                {{ scope.row.status | filterState(scope.row) }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" style="width: 80px"
                                       @click="handleDetail(1,scope.row)">
                                详情
                            </el-button>
                            <el-button type="warning" size="mini"
                                       v-if="showEdit(scope.row)"
                                       style="margin-top: 10px;margin-left: 0;width: 80px"
                                       @click="handleDetail(undefined,scope.row)">编辑
                            </el-button>
                            <el-popconfirm title="确定停止循环？"
                                           v-if="scope.row.pushType&&scope.row.isRepeat"
                                           @confirm="handleChangeState({id:scope.row.id,isRepeat:0})">
                                <el-button slot="reference" size="mini"
                                           type="warning"
                                           style="margin-top: 10px;margin-left: 0">
                                    停止循环
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm title="确定取消弹窗？"
                                           @confirm="handleChangeState({id:scope.row.id,pushType:0})">
                                <el-button slot="reference" size="mini"
                                           type="warning"
                                           v-if="scope.row.pushType===1"
                                           style="margin-top: 10px;margin-left: 0">
                                    取消弹窗
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm title="确定删除？"
                                           @confirm="handleChangeState({id:scope.row.id,status: 1,isDelete: 1})"
                                           v-if="scope.row.isDelete!==1">
                                <el-button slot="reference" size="mini" style="margin-top: 10px;width: 80px"
                                           type="danger">删除
                                </el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>

                </el-table>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
    </div>
</template>

<script>
import {getMessageListApi, updateMessageApi} from '@/www/urls/message'
import {messageTypeOptions, messageChannelOptions} from '@/data/message'
import comListSearch from "../../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import dayjs from "dayjs";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    messageType: undefined,
    messageTitle: undefined
})
export default {
    name: "messageList",
    data () {
        return {
            messageTypeOptions,
            messageChannelOptions,
            loading: false,
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    filters: {
        filterState (val, record) {
            if (record.isDelete === 1)
                return '已删除'
            if (val === 1)
                return '已取消'
            return !record.pushTime || (dayjs(record.pushTime).isBefore(dayjs())) ? '已发布' : '待发布'
        },
        filterStateType (val, record) {
            if (val === 1)
                return 'info'
            return !record.pushTime || (dayjs(record.pushTime).isBefore(dayjs())) ? 'success' : 'warning'
        }
    },
    components: {
        comListSearch,
        SectionContent
    },
    mounted () {
        this.getList();
    },
    activated () {
        if (this.$route.query.t !== this._t) {
            this._t = this.$route.query.t;
            this.getList();
        }
    },
    methods: {
        showEdit (record) {
            return record.status === 0 && dayjs(record.pushTime).isAfter(dayjs())
        },
        async getList (page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getMessageListApi(this.form)
            this.loading = false
            if (ret.success)
                this.result = ret.data;
            else this.$message.error(ret.errors[0].message)

        },
        handleDetail (type, record) {
            this.$router.push({
                path: '/messageAdd',
                query: {
                    id: record.id,
                    type
                }
            })
        },
        resetClick () {
            this.form = createForm();
            this.getList(1)
        },
        handleAdd () {
            this.$router.push({
                path: '/messageAdd'
            })
        },
        async handleChangeState (form) {
            this.loading = true;
            const ret = await updateMessageApi(form)
            if (!ret.success) {
                this.loading = false;
                return this.$message.error(ret.errors[0].message)
            }
            this.getList();
        }
    }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
    margin-top: 10px;
}

.message-info-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>