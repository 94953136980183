import http from "@/common/js/http";

/**
 * 新增通知
 * @param form
 * @return {Promise<*|{success: boolean, errors: [{code: number, message: string}]}|{success: boolean, errors: [{message: string}]}|undefined>}
 */
export const addMessageApi = async (form) => {
    return await http(`/message/addMessage`, form, 'POST');
};

/**
 * 获取通知详情
 * @param id
 * @return {Promise<*|{success: boolean, errors: [{code: number, message: string}]}|{success: boolean, errors: [{message: string}]}|undefined>}
 */
export const getMessageDetailApi = async (id) => {
    return await http(`/message/getGetMessage/${id}`);
};

/**
 * 获取通知列表
 * @param form
 * @return {Promise<*|{success: boolean, errors: [{code: number, message: string}]}|{success: boolean, errors: [{message: string}]}|undefined>}
 */

export const getMessageListApi = async (form) => {
    return await http(`/message/getMessageInfoList`, form, 'POST');
};

/**
 * 通过渠道获取通知
 * @param form
 * @return {Promise<*|{success: boolean, errors: [{code: number, message: string}]}|{success: boolean, errors: [{message: string}]}|undefined>}
 */
export const getMessageListByChannelApi = async (form) => {
    return await http(`/message/getMessageInfoListByChannel`, form, 'POST');
};

/**
 * 更新通知信息
 * @param form
 * @return {Promise<*|{success: boolean, errors: [{code: number, message: string}]}|{success: boolean, errors: [{message: string}]}|undefined>}
 */
export const updateMessageApi = async (form) => {
    return await http(`/message/updateMessage`, form, 'POST');
};
